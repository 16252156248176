import React, { useEffect, useState } from 'react';
import Header from './Header'
import PlaceholdingLoader from './PlaceholdingLoader'

import { Link } from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import eth_bal from '../images/eth_bal.png';
import eth_bal_price from '../images/eth_bal_price.png';
import { Container, Dropdown } from 'react-bootstrap'
import eth from '../images/eth.png';
import bnb from '../images/bnb.png'
import busd from '../images/busd.png'
import leaf from '../images/leaf.png'
import Slider from "@material-ui/core/Slider";
import { getRewardTokenInfo, getUser, getUserReward, Claim, getUSDTprice, getPoolLength, getPoolInfoLimit, FARMS, plan } from "../hooks/UseStake"
import { sleep, UseTokenInfo, checkIsApproved, approveContract, getUserBalance, searchedValue, toFixedNumber } from "../hooks/UseContract"
import { ONEDAYINSECONDS } from '../config/env';
import { getAccount } from "../hooks/useAccount"
import Form from 'react-bootstrap/Form';
import { rewardTokenDetails, irenaLpToken, stakeTokens } from '../config/Farms';
import calculator from '../images/Calculator.png'
const Lockedstaking = () => {
 
  const [selectRewardToken, setSelectRewardToken] = useState(rewardTokenDetails[0]);
  const [valueone, setValueOne] = React.useState(0);
  const [valuetwo, setValueTwo] = React.useState([]);
  const [valuethree, setValueThree] = React.useState([20]);
  const [tokenInfo, setTokenInfo] = useState();
  const [pending, setPending] = useState(0)
  const [rewardTokenInfo, setRewardTokenInfo] = useState({});
  const [claim, setShowClaim] = useState(false)
  const [pid, setPid] = useState();
  const [poolLength, setPoolLength] = useState(0);
  const [poolLimit, setPoolLimit] = useState([]);
  const [userStakedAmount, setUserStakedAmount] = useState(0);
  const [apy, setApy] = useState();
  const [step, setStep] = useState(1);
  const [accountInfo, setAccountInfo] = useState("");
  const [userstatedate, setuserstatedate] = useState(0)
  const [selectDay, setSelectDay] = useState(false)
  // const [data, setData] = useState([])
  const [loading, setLoading] = useState(false);
  const [mark, setMark] = useState([]);
  const [userTokenBalance, setUserTokenBalance] = useState("");
  const [perDayReward, setPerDayReward] = useState()
  const [reward, setReward] = useState();
  const [contentLoading, setContentLoading] = useState(false);
  const [placeholding, setPlaceholding] = React.useState(true);
  const [selectStakeToken, setSelectStakeToken] = useState(stakeTokens[0]);
  useEffect(() => {
    showloader()
    getData();
    setAccountInfo(getAccount())
  }, [])


  const handleChange = (event, newValue) => {
    console.log("handleChange one", newValue)
    setValueOne(newValue);
  };

  function valuetextone(valueone) {
    return `${valueone}`;
  }


  const handleChangeTwo = (event, newValue) => {
    // console.log("handleChangeTwo..",newValue)
    // poolDetails(newValue)
    setValueTwo(newValue);
  };

  function valuetexttwo(valuetwo) {
    // console.log("valuetexttwo",valuetwo)
    return `${valuetwo}`;
  }

  const handleChangeThree = (event, newValue) => {
    setValueThree(newValue);
  };

  function valuetextthree(valuethree) {
    return `${valuethree}`;
  }

  const getData = async () => {
    const userbalance = getAccount() ? await getUserBalance(irenaLpToken, getAccount()) : 1000;
    // console.log("userBalance", userbalance,irenaLpToken)
    setUserTokenBalance(userbalance)
    setValueOne(0);
    var arr = []
    var data = await getPoolLength()
    // console.log("data", data);
    if (data) setPoolLength(data);
    var pool = await getPoolInfoLimit(0, data)
    // console.log("firstPool",pool)
    if (pool) {
      var poolArr = [];
      for (let [index, value] of pool.entries()) {
        if (index <= 3) {
          var poolDetail = await plan(index);
          poolArr.push(poolDetail);
        }
      }
      setPoolLimit(poolArr);
      // console.log(poolArr, 'pool')
      // setValueTwo(Math.floor(parseInt(poolArr[0]?.lockedPeriod * 1000) / (1000 * 60 * 60 * 24)))
      setValueTwo(0)
      const initialDay = Math.floor(parseInt(poolArr[0]?.lockedPeriod * 1000) / (1000 * 60 * 60 * 24))
      console.log("poolArr_poolArr_V", poolArr);
      poolArr.map((val, index) => {
        var marks = {
          lable: `${Math.floor(parseInt(val.lockedPeriod * 1000) / (1000 * 60 * 60 * 24))}Days`,
          value: Math.floor(parseInt(val.lockedPeriod * 1000) / (1000 * 60 * 60 * 24))
        }
        console.log("marksmarksmarks", marks);
        arr.push(marks)
      })

      setMark(arr)
      setLoading(true);
      // console.log("valuetwo in getdata",valuetwo,initialDay)
      await poolDetails(initialDay)
      hideloader()
    }
  }

  // const poolDetails = async (value) => {
  //   // console.log("poolDetails Calc",value)
  //   // showloader()
  //   // setContentLoading(true);
  //   // if (value) {
  //   //   const lockedPeriod = (1000 * 60 * 60 * 24 / 1000) * (value);
  //   //   const filterData = await searchedValue(poolLimit, lockedPeriod);
  //   //   // console.log("filterData...", filterData);
  //   //   var RewardTokenInfo = await getRewardTokenInfo();
  //   //   var poolday = ((parseInt(lockedPeriod) / ONEDAYINSECONDS))
  //   //   // setpoolDays(poolday.toFixed());

  //   //   var TokenInfo = await UseTokenInfo(irenaLpToken)
  //   //   // setTokenInfo(TokenInfo);
  //   //   // console.log("filterDataa...",filterData[0]?.UmudiPerBlock,lockedPeriod);
  //   //   var apy = ((parseInt(filterData[0]?.UmudiPerBlock) / 10 ** 18) * (lockedPeriod)).toFixed(2);

  //   //   // console.log("apy", poolday,apy, "valueone", valueone, "UmudiPerBlock", filterData[0]?.UmudiPerBlock, " TokenInfo decimals", TokenInfo?.decimals, "RewardTokenInfo",RewardTokenInfo);

  //   //   const amount = ((apy * (parseInt(userTokenBalance*valueone)/100))) * 10 ** TokenInfo?.decimals;
  //   //   const fixedAmount = await toFixedNumber(amount)
  //   //   // console.log("TOFIXEDNUMBER", fixedAmount);
  //   //   setPerDayReward(amount);
  //   //   // console.log("apy DATA....", amount, "=====", amount * valueone);
  //   //   // const rewardReturn = await getUSDTprice(fixedAmount, irenaLpToken);

  //   //   // setReward(rewardReturn ? (rewardReturn / 10 ** RewardTokenInfo?.decimals).toFixed(14) : "0");
  //   //   setReward(fixedAmount/100);
  //   //  setContentLoading(false)
  //   //   // hideloader()
  //   // } else {
  //   //   // console.log("NO VALUEs........");
  //   //   hideloader()

  //   // }

  // }
  const poolDetails = async () => {
    const stateDetail = selectStakeToken
    const rewardDetail = selectRewardToken
    const amount = valueone * 10 ** stateDetail?.decimal

    const getRewardTokens = await getUSDTprice(amount, stateDetail?.address, rewardDetail?.address);
    console.log("getRewardTokens", getRewardTokens, amount, stateDetail, rewardDetail);
    console.log("percenrage", getRewardTokens / stateDetail?.decimal);
    setReward(getRewardTokens / parseInt(10 ** stateDetail?.decimal));
  }

  const showloader = async () => {
    document.getElementById("loader_div").style.display = "block";
  }

  const hideloader = async () => {
    document.getElementById("loader_div").style.display = "none";
  }



  return (
    <section>
      <div className='showloader minwidth-534 text-center' id="loader_div">
        <div className='loader ' >
          <img src={leaf} className="spin" />
        </div>
      </div>
      <div className='innerpages calculatepage pb-4'>
        <Header />

        <div className='container container_custom pt-main-top'>
          <div className='headsec mb-3'>
            <div>
              <h3 className='headtitle'>PAYU Calculator</h3>
            </div>
            {/* {console.log("rewardReturn..", reward)} */}
            <div>
              {/* <Link to="/" className='headright'>ROI Calculator</Link> */}
              {/* <Form.Select className='selct_neew_style' onChange={(e) => { poolDetails(e) }}>
                <option value={"default"} disabled selected> Choose Days</option>

                {
                  loading ? poolLimit && poolLimit.map((val, index) =>
                    <option value={index}>{Math.floor(parseInt(val.lockedPeriod * 1000) / (1000 * 60 * 60 * 24))} Days</option>)
                    :
                    <option value={"default"} disabled> Fetching Data.......</option>
                }

              </Form.Select> */}
            </div>
          </div>
          <div className='cardsec'>
            {console.log("STAKEDCAL", selectStakeToken)}
            <div className='secondcard mb-4'>
              <div class="card shadowstyle">
                <div class="card-body">
                  <div className='row'>
                    <div className='col-xl-6 leftsec'>
                      <div>
                        <p className='text_sliver'>Investment Amount {userTokenBalance}</p>
                        {/* <div className='stakingpage'> */}

                        <Slider
                          getAriaLabel={() => "Temperature range"}
                          value={valueone}
                          onChange={handleChange}
                          valueLabelDisplay="auto"
                          getAriaValueText={valuetextone}
                          max={100}
                        />

                        {/* </div> */}
                      </div>

                      <div className='mt-4'>
                        <p className='text_sliver'>Exit Date</p>
                        <div className='d-flex align-items-center justify-content-between progress_text_div'>
                          <p className='val_cal'></p>
                          <p className='val_cal'>{userstatedate}</p>

                        </div>
                        {/* {console.log("marks",mark)} */}
                        <Slider
                          getAriaLabel={() => "Temperature range"}
                          value={valuetwo}
                          onChange={handleChangeTwo}
                          valueLabelDisplay="auto"
                          getAriaValueText={valuetexttwo}
                          step={null}
                          marks={mark}
                          max={3000}
                        />
                      </div>

                      <div className='mt-4'>

                        <div className='flex_drop_div'>
                          <p>Stake Token :</p>
                          {console.log("selectStakeToken", selectStakeToken)}
                          <Dropdown className='dropdwn_trna' onSelect={(eventKey) => { setSelectStakeToken(JSON.parse(eventKey)) }}>
                            <Dropdown.Toggle variant="success" id="dropdown-basic1">
                              <img src={eth} className="img-fluid me-1" />
                              {selectStakeToken ? selectStakeToken.token : stakeTokens[0].token}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {stakeTokens && stakeTokens?.map((value, index) =>
                                (<Dropdown.Item href="javascript:void(0)" eventKey={JSON.stringify(value)}><img src={eth} className="img-fluid imgwht" />{value?.token}</Dropdown.Item>)
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                          <p className='ms-2'>Reward Token :</p>
                          <Dropdown className='dropdwn_trna me-3' onSelect={(eventKey) => { setSelectRewardToken(JSON.parse(eventKey)) }}>
                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                              <img src={eth} className="img-fluid me-1" />
                              {selectRewardToken ? selectRewardToken.token : rewardTokenDetails[0].token}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {rewardTokenDetails && rewardTokenDetails?.map((value, index) => {

                                // if (selectStakeToken?.token == "PAYU") {

                                //   if (value?.token === selectStakeToken?.token) {
                                //     return ((<Dropdown.Item href="javascript:void(0)" eventKey={JSON.stringify(value)}><img src={eth} className="img-fluid imgwht" />{value?.token}</Dropdown.Item>))
                                //   }
                                //   if (value?.token == "USDT") {
                                    return ((<Dropdown.Item href="javascript:void(0)" eventKey={JSON.stringify(value)}><img src={eth} className="img-fluid imgwht" />{value?.token}</Dropdown.Item>))
                                //   }
                                // }
                                // else if (value?.token == selectStakeToken?.token) {
                                //   return ((<Dropdown.Item href="javascript:void(0)" eventKey={JSON.stringify(value)}><img src={eth} className="img-fluid imgwht" />{value?.token}</Dropdown.Item>))
                                // }
                                // else {
                                //   return ((<Dropdown.Item href="javascript:void(0)" eventKey={JSON.stringify(value)}><img src={eth} className="img-fluid imgwht" />{value?.token}</Dropdown.Item>))
                                // }
                              }
                              )}

                            </Dropdown.Menu>
                          </Dropdown>

                        </div>
                        {/* <div className='d-flex align-items-center justify-content-between progress_text_div'>
                          <p className='val_cal'>$3</p>
                          <p className='val_cal'>$100</p>
                        </div> */}
                        {/* {
                          contentLoading ? <PlaceholdingLoader classnames="stripe medium-stripe mb-2"/> : 
                          
                        } */}
                        <Slider
                          getAriaLabel={() => "Temperature range"}
                          value={reward ? parseInt(reward) : 0}
                          onChange={handleChangeThree}
                          valueLabelDisplay="auto"
                          getAriaValueText={valuetextthree}
                        // valueLabelFormat={valueLabelFormat}
                        />
                      </div>

                      <button className="nav-link btn_yellow calc_btn mt-4" onClick={() => { poolDetails() }}>Calculate
                        <span><img src={calculator} /></span>
                      </button>
                    </div>
                    <div className='col-xl-6'>
                      <div className='row rightsection'>
                        <div className='col-md-5 midsec'>
                          <div>
                            {/* {console.log("INVESTMENT AMOUNT: ", parseInt(userTokenBalance*valueone)/100)} */}
                            <p className='mid_title'>INVESTMENT</p>
                            {/* <p className='mid_value'>{parseInt(userTokenBalance*valueone)/100}</p> */}
                            <p className='mid_value'>{valueone}</p>
                          </div>
                          <div>
                            <p className='mid_title'>RETURN</p>

                            <p className='mid_value'>{contentLoading ? <PlaceholdingLoader classnames="stripe small-stripe mb-2" parentclassname="loading" /> : reward ? parseInt(reward) : 0} {selectRewardToken ? selectRewardToken.token : rewardTokenDetails[0].token}</p>
                          </div>
                          <div>
                            <p className='mid_title'>DAYS</p>
                            <p className='mid_value'>{valuetwo == 0 ? "Selected day" : valuetwo}</p>
                          </div>
                        </div>
                        <div className='col-md-7 lastrightsec'>
                          <p className='title'>PAYU Calculator</p>
                          <p className='desc'>Calculate rate of interest between profit and cost of investment and see benefits according to them</p>

                          <div className='listdesc mt-5'>
                            <div className='listlines'>

                              <p className='yellowcircle list_desc mb-4'>Investment amount starts from min 20k PAYU runs till 1M PAYU</p>
                            </div>
                            <div className='listlines'>
                              <p className='bluecircle list_desc mb-4'>Investment amount starts from min 20k PAYU runs till 1M PAYU</p>
                            </div>
                            <div className='listlines'>
                              <p className='lightcircle list_desc mb-4'>Investment amount starts from min 20k PAYU runs till 1M PAYU</p>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>


    </section>




  )
}

export default Lockedstaking
