export const  sleep = (ms) => 
{
    new Promise(resolve => setTimeout(resolve, ms))
}

export const FARMS = [{
    pid: 0,
    lpAddress: "0xF5349B154829a8Eceb99FDdb90E81E53dB61a8dD",
    lpSymbol: "TMAT-XDC LP",
    tokenSymbol: "TMAT",
    tokenAddress: "0x4fb5519aD7bC90B17de1343894FeFC9e320bB745",
    quoteTokenSymbol: "XDC",
    quoteTokenAddress: "0xe78E01449fC8Efd222047A28005Da3B1058BEE28",
    isTokenOnly: false
},
{
    pid: 1,
    lpAddress: "0x0B28Fd83f6e050dB22eA646BC9609f5d4D590E19",
    lpSymbol: "XSP-XDC LP",
    tokenSymbol: "XSP",
    tokenAddress: "0x00A0aa653F4230075cE6e9f116188ac0fb36637B",
    quoteTokenSymbol: "XDC",
    quoteTokenAddress: "0xe78E01449fC8Efd222047A28005Da3B1058BEE28",
    isTokenOnly: false
},
{
    pid: 2,
    lpAddress: "0xF5349B154829a8Eceb99FDdb90E81E53dB61a8dD", // native - usd
    lpSymbol: "TMAT",
    tokenSymbol: "TMAT",
    tokenAddress: "0x4fb5519aD7bC90B17de1343894FeFC9e320bB745",
    quoteTokenSymbol: "XDC",
    quoteTokenAddress: "0xe78E01449fC8Efd222047A28005Da3B1058BEE28",
    isTokenOnly: true
}
// ,
// {
//     pid: 3,
//     lpAddress: "",
//     lpSymbol: "WXDC",
//     tokenSymbol: "WXDC",
//     tokenAddress: "",
//     quoteTokenSymbol: "",
//     quoteTokenAddress: "",
//     isTokenOnly: true
// }
]

export const stakeTokens = [
    // Main-net

    // {
    //     token: "USDT",
    //     address: "0x55d398326f99059fF775485246999027B3197955",
    //     decimal: 18
    // },
    // {
    //     token: "IRENA",
    //     address: "0x9eeb6c5ff183e6001c65a12d70026b900ae76781",
    //     decimal: 9
    // }


    
    {
        token:'PAYU',
        address:"0x9AeB2E6DD8d55E14292ACFCFC4077e33106e4144",
        decimal :18
    },
     
    // {
    //     token:'USDT',
    //     address:"0x55d398326f99059fF775485246999027B3197955",
    //     decimal :18
    // },
    // {
    //     token:'BNB',
    //     address:"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
    //     decimal :18
    // },
    

    // {
    //     token:'DOGE',
    //     address:"0xba2ae424d960c26247dd6c32edc70b295c744c43",
    //     decimal : 8
    // },
    // {
    //     token:'SHIBA',
    //     address:"0x2859e4544C4bB03966803b044A93563Bd2D0DD4D",
    //     decimal : 18
    // },

    // {
    //     token:'FLOKI',
    //     address:"0xfb5B838b6cfEEdC2873aB27866079AC55363D37E",
    //     decimal : 9
    // },
    // {
    //     token:'BABY DOGE',
    //     address:"0xc748673057861a797275CD8A068AbB95A902e8de",
    //     decimal : 9
    // },
    // {
    //     token:'PITTBUL',
    //     address:"0xA57ac35CE91Ee92CaEfAA8dc04140C8e232c2E50",
    //     decimal : 9
    // },
    // {
    //     token:'QUACK',
    //     address:"0xD74b782E05AA25c50e7330Af541d46E18f36661C",
    //     decimal : 9
    // },
    // {
    //     token:'HAMSTER',
    //     address:"0x679d5b2d94f454c950d683d159b87aa8eae37c9e",
    //     decimal : 7
    // },

    


    // Test-Net
    
    // {
    //     token: "COOL",
    //     address: "0xDFCd0b91AE2BB34a90D9b4970Dc113DFaf25004d",
    //     decimal: 18
    // },
    // {
    //     token: "BUSD",
    //     address: "0xbCf448aa88d80C1B7bb8Fb5A1e68cd4B6c11c354",
    //     decimal: 18
    // },

  


]

export const rewardTokenDetails = [

    // Main-Net

    // {   
    //     token: "IRENA",
    //     address: "0x9eeb6c5ff183e6001c65a12d70026b900ae76781"
    // },
   
    // {
    //     token: "ETH",
    //     address: "0x2170Ed0880ac9A755fd29B2688956BD959F933F8"
    // },
    // {
    //     token: "BNB",
    //     address: "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
    // },
    // {
    //     token: "MATIC",
    //     address: "0xCC42724C6683B7E57334c4E856f4c9965ED682bD"
    // },


 
    

    // {
    //     token:'DOGE',
    //     address:"0xba2ae424d960c26247dd6c32edc70b295c744c43",
    //     decimal : 8
    // },
    // {
    //     token:'SHIBA',
    //     address:"0x2859e4544C4bB03966803b044A93563Bd2D0DD4D",
    //     decimal : 18
    // },
    {
        token:'BITCOIN',
        address:"0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c",
        decimal : 18
    },

    

    // {
    //     token:'PAYU',
    //     address:"0x9AeB2E6DD8d55E14292ACFCFC4077e33106e4144",
    //     decimal :18
    // },
    // {
    //     token:'DOGE',
    //     address:"0xba2ae424d960c26247dd6c32edc70b295c744c43",
    //     decimal : 8
    // },
    // {
    //     token:'FLOKI',
    //     address:"0xfb5B838b6cfEEdC2873aB27866079AC55363D37E",
    //     decimal : 9
    // },
    // {
    //     token:'BABY DOGE',
    //     address:"0xc748673057861a797275CD8A068AbB95A902e8de",
    //     decimal : 9
    // },
    // {
    //     token:'PITTBUL',
    //     address:"0xA57ac35CE91Ee92CaEfAA8dc04140C8e232c2E50",
    //     decimal : 9
    // },
    // {
    //     token:'QUACK',
    //     address:"0xD74b782E05AA25c50e7330Af541d46E18f36661C",
    //     decimal : 9
    // },
    // {
    //     token:'HAMSTER',
    //     address:"0x679d5b2d94f454c950d683d159b87aa8eae37c9e",
    //     decimal : 7
    // },
  


    // Test-Net 
//     {
//         token: "COOL",
//         address: "0xDFCd0b91AE2BB34a90D9b4970Dc113DFaf25004d",
//         decimal: 18
//     },
//     {
//         token: "DEMO",
//         address: "0x4e5687Bcb8172081fd926B105B62E2CcBd542Bbf"
//     },
//     {
//         token: "LBM",
//         address: "0x4849027a11E584916968Ccd991eC4Ce956Ef7Fef"
//     },
//     {
//         token: "MAT",
//         address: "0x2Bbeaf7BB69d2296Aa1d09c9198a111f1A2E6fD9"
//     },
//     {
//         token: "HOPE",
//         address: "0x96F0656922789E631Fd8Ef19C64aa5200b89aaDb"
//     },
// {
//     token: "BUSD",
//     address: "0xbCf448aa88d80C1B7bb8Fb5A1e68cd4B6c11c354",
//     decimal: 18
// },
// 
]

export const irenaLpToken = "0x9eeb6c5ff183e6001c65a12d70026b900ae76781"