import React, { Component, useState, useEffect, } from 'react'
import disabled from '../images/disabled.png'
// import modalbg from '../images/modalbg-min.png'
import Header from "./Header";
import Footer from "./Footer";
import WalletModal from "./WalletModal"
import PlaceholdingLoader from './PlaceholdingLoader'
import Slider from "@material-ui/core/Slider";
import { Dropdown } from 'react-bootstrap'
import { Link } from "react-router-dom"
import BigNumber from 'bignumber.js';
import logo from '../images/logo.png'
import eth from '../images/eth.png'
import bnb from '../images/bnb.png'
import busd from '../images/busd.png'
import calculator from '../images/Calculator.png'
import reward_img from '../images/reward_img.png'
import graph_img from '../images/graph_img.png'
import star from '../images/star.png'
import wallet_icon from '../images/wallet.png'
import stake_icon from '../images/stake.png'
import { useWeb3 } from "../hooks/useWeb3";
import { MetamaskWallet, WalletConnect, BinanceWallet } from '../hooks/useWallet';
import toast, { Toaster } from 'react-hot-toast';
import { getRewardTokenInfo, getUser, getUserReward, Claim, getUSDTprice, getPoolLength, getPoolInfoLimit, FARMS, plan } from "../hooks/UseStake"
import { CHAINS } from "../config/env"
import { sleep, UseTokenInfo, checkIsApproved, approveContract, getUserBalance, searchedValue, toFixedNumber } from "../hooks/UseContract"
import { ONEDAYINSECONDS } from '../config/env';
import { getAccount } from "../hooks/useAccount"
import { rewardTokenDetails, irenaLpToken, stakeTokens } from '../config/Farms';

import banner_img from '../images/banner_img.png'
import logo_coin from '../images/logo_coin.png'
import earn_icon from '../images/earn.png'
import green_shadow from '../images/green_bg.png';
import blue_shadow from '../images/blue_bg.png';
import pink_shadow from '../images/pink_bg.png'
import leaf from '../images/leaf.png'

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Home = () => {
    const [accountInfo, setAccountInfo] = useState("");
    const [walletConnect, set_walletConnect] = useState("")
    const [poolLength, setPoolLength] = useState(0);
    const [poolLimit, setPoolLimit] = useState([]);
    const [key, setKey] = useState('first');
    const [lpsymbol, setlpsymbol] = useState({})
    const [wallet, setShowWallet] = useState(false)
    const [userTokenBalance, setUserTokenBalance] = useState("");
    const [perDayReward, setPerDayReward] = useState()
    const [reward, setReward] = useState();
    const [loading, setLoading] = useState(false);
    const [mark, setMark] = useState([]);
    const [valueone, setValueOne] = React.useState([30]);
    const [valuetwo, setValueTwo] = React.useState([0]);
    const [valuethree, setValueThree] = React.useState([20]);
    const [contentLoading, setContentLoading] = useState(false);

    const [selectRewardToken, setSelectRewardToken] = useState(rewardTokenDetails[0]);
    const [rewardListToken, setRewardListToken] = useState(rewardTokenDetails);
    const [selectStakeToken, setSelectStakeToken] = useState();





    const options = {

        responsiveClass: true,
        nav: true,
        dots: false,
        autoplay: false,
        center: false,
        margin: 20,

        loop:false,
        // navText: ['<i class="fa fa-long-arrow-left" aria-hidden="true"></i>','<i class="fa fa-long-arrow-right" aria-hidden="true"></i>'],
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
            },
            400: {
                items: 1,
            },
            600: {
                items: 2,
            },
            700: {
                items: 2,
            },
            1000: {
                items: 2,
            },
            1200: {
                items: 2,
            }
        },
    };



    function addtabclass() {
        document.getElementById("tabid").classList.add("completed")
    }

    useEffect(() => {
        // showloader()
        getData();
        if (sessionStorage.accountInfo) {
            setAccountInfo(sessionStorage.getItem("accountInfo"))
        } else {
            console.log("SORRY...No Wallet Address")
        }
    }, [accountInfo]);

    const logOut = async () => {
        setAccountInfo("");
        sessionStorage.removeItem("accountInfo");
        window.location.reload()
    }


    const setTabKey = (value) => {
        setKey(value)
    }

    const handleChange = (event, newValue) => {
        setValueOne(newValue);
    };

    function valuetextone(valueone) {
        return `${valueone}`;
    }


    const handleChangeTwo = (event, newValue) => {
        poolDetails(newValue)
        setValueTwo(newValue);
    };

    function valuetexttwo(valuetwo) {
        return `${valuetwo}`;
    }

    const handleChangeThree = (event, newValue) => {
        setValueThree(newValue);
    };

    function valuetextthree(valuethree) {
        return `${valuethree}`;
    }

    const getData = async () => {
        const userbalance = accountInfo ? await getUserBalance(irenaLpToken, getAccount()) : 1000;
        // console.log("userBalance", userbalance,irenaLpToken)
        setUserTokenBalance(userbalance)
        setValueOne(0);
        var arr = []
        var data = await getPoolLength()
        // console.log("data", data);
        if (data) setPoolLength(data);
        var pool = await getPoolInfoLimit(0, data)
        console.log("firstPool", pool)
        if (pool) {
            var poolArr = [];
            for (let [index, value] of pool.entries()) {
                if (index <= 2) {
                    var poolDetail = await plan(index)
                    console.log("poolDetail", poolDetail);
                    poolArr.push(poolDetail);
                }

            }
            setPoolLimit(poolArr);
            console.log(poolArr, 'pool')
            setValueTwo(Math.floor(parseInt(poolArr[0]?.lockedPeriod * 1000) / (1000 * 60 * 60 * 24)))
            poolArr.map((val, index) => {
                var marks = {
                    lable: `${Math.floor(parseInt(val?.lockedPeriod * 1000) / (1000 * 60 * 60 * 24))}Days`,
                    value: Math.floor(parseInt(val?.lockedPeriod * 1000) / (1000 * 60 * 60 * 24))
                }
                arr.push(marks)
            })
            setMark(arr)
            setLoading(true);
            //   console.log("valuetwo in getdata",valuetwo)
            await poolDetails(valuetwo)
            //   hideloader()
        }
    }

    const poolDetails = async (value) => {
        // console.log("poolDetails Calc",value)
        setContentLoading(true);
        if (value) {
            const lockedPeriod = (1000 * 60 * 60 * 24 / 1000) * (value);
            const filterData = await searchedValue(poolLimit, lockedPeriod);
            //   console.log("filterData...", filterData[0]);
            //   var RewardTokenInfo = await getRewardTokenInfo();
            var poolday = ((parseInt(lockedPeriod) / ONEDAYINSECONDS))
            // setpoolDays(poolday.toFixed());

            var TokenInfo = await UseTokenInfo(irenaLpToken)
            // setTokenInfo(TokenInfo);

            var apy = ((filterData[0]?.UmudiPerBlock / 10 ** TokenInfo?.decimals) * Number(poolday)).toFixed(2);

            //   console.log("apy", apy, "valueone", valueone, "UmudiPerBlock", filterData[0]?.UmudiPerBlock, " TokenInfo decimals", TokenInfo?.decimals, "RewardTokenInfo",RewardTokenInfo);

            const amount = ((apy * (parseInt(userTokenBalance * valueone) / 100))) * 10 ** TokenInfo?.decimals;
            const fixedAmount = await toFixedNumber(amount)
            //   console.log("TOFIXEDNUMBER", fixedAmount);
            setPerDayReward(amount);
            //   console.log("apy DATA....", amount, "=====", amount * valueone);
            //   const rewardReturn = await getUSDTprice(fixedAmount, irenaLpToken);
            //   console.log("rewardReturn..", rewardReturn);
            //   setReward(rewardReturn ? (rewardReturn / 10 ** RewardTokenInfo?.decimals).toFixed(14) : "0");
            setReward(fixedAmount / 100);
            setContentLoading(false)
            //   hideloader()
        } else {
            //   console.log("NO VALUEs........");
            //   hideloader()

        }

    }

    const selectStakeTokens = async (eventKey) => {
        // console.log(" set selectStakeTokens","----",JSON.parse(eventKey));
        let selectValue = JSON.parse(eventKey);
        if (selectValue.token == "BUSD") {
            // var filteredArray = rewardTokenDetails.filter(function(e) { return e.token !== 'RIA' });
            setRewardListToken(rewardTokenDetails)
            setSelectRewardToken()
        }
        else if (selectValue.token == "RIA") {

            const found = rewardTokenDetails.find(element => element.token == "RIA");
            setRewardListToken([found])
            setSelectRewardToken()
        }
    }



    //   const showloader = async () => {
    //     document.getElementById("loader_div").style.display = "block";
    //   }

    //   const hideloader = async () => {
    //     document.getElementById("loader_div").style.display = "none";
    //   }
    const calculate = async () => {
        const stateDetail = selectStakeToken
        const rewardDetail = selectRewardToken
        const amount = valueone * 10 ** stateDetail?.decimal

        const getRewardTokens = await getUSDTprice(amount, stateDetail?.address, rewardDetail?.address);
        console.log("getRewardTokens", getRewardTokens, amount, stateDetail, rewardDetail);
        console.log("percenrage", getRewardTokens / stateDetail?.decimal);
        setReward(getRewardTokens / parseInt(10 ** stateDetail?.decimal));
    }
    return (
        <div className='homepage_bg'>
            <section>
                {/* <div className='showloader minwidth-534 text-center' id="loader_div">
        <div className='loader ' >
          <img src={leaf} className="spin" />
        </div>
      </div> */}
            </section>
            <div className='landing_bg'>
                <div className='home_page'>
                    <Header />
                    <div className='banner_sec_pos'>
                        <h3 className='text_yellow banner_txt_yel'>Payu Coin LockedStaking</h3>
                        <p className='banner_txt_desc'>You can earn different coins by staking your Payu coins and Busd coins on the Payu staking platform.</p>
                        <div className='btn_secs mt-4'>
                            {
                                (accountInfo == "" || accountInfo == null) ?
                                    <button className="nav-link connect_wallet_btn me-3" onClick={() => { setShowWallet(true) }}>Connect Wallet  <span><img src={wallet_icon} /></span></button> :
                                    <button className="nav-link connect_wallet_btn me-3" onClick={() => { logOut() }}>{`${accountInfo.substring(0, 5)}...${accountInfo.substring(38, 42)}`}<span><img src={wallet_icon} /></span></button>
                            }                     
                               {/* <Link to="/staking" className="nav-link btn_blue" state={{ from: accountInfo }}>Stake Now<span><img src={stake_icon} /></span></Link> */}

                        </div>

                    </div>

                    <section className='container container_custom home_sec_1 pt-main-top'>
                        <div className='row align-items-center justify-content-end py-5 banner_row'>


                        </div>
                        {/* <div className='row coin_row pb-5'>
                    <div className='col-12 col-sm-6 col-md-3 mb-3'>
                        <div className='d-flex align-items-center'>
                            <img src={logo_coin} className="img-fluid" />
                            <span className='text_yellow ms-2'>Lorem Ipsum</span>
                        </div>
                    </div>
                    <div className='col-12 col-sm-6 col-md-3 mb-3'>
                        <div className='d-flex align-items-center'>
                            <img src={logo_coin} className="img-fluid" />
                            <span className='text_yellow ms-2'>Lorem Ipsum</span>
                        </div>
                    </div>
                    <div className='col-12 col-sm-6 col-md-3 mb-3'>
                        <div className='d-flex align-items-center'>
                            <img src={logo_coin} className="img-fluid" />
                            <span className='text_yellow ms-2'>Lorem Ipsum</span>
                        </div>
                    </div>
                    <div className='col-12 col-sm-6 col-md-3 mb-3'>
                        <div className='d-flex align-items-center'>
                            <img src={logo_coin} className="img-fluid" />
                            <span className='text_yellow ms-2'>Lorem Ipsum</span>
                        </div>
                    </div>
                </div> */}
                    </section>

                </div>



            </div>
            <section className='container container_custom home_sec_2 mt-5'>
                <div className='bg_shad'>
                    <img src={green_shadow} />
                </div>
                <div className='row align-items-center row_rev'>
                    <div className='col-12 col-md-6 mt-5 mt-md-0'>
                        <div className='card card_shadow'>
                            <div className='card-body px-3 px-lg-5 py-4'>
                                <div>
                                    <p className='text_sliver'>Investment Amount</p>
                                    <div className='d-flex align-items-center justify-content-between progress_text_div'>
                                        <p>{valueone}</p>

                                    </div>
                                    <Slider
                                        getAriaLabel={() => "Temperature range"}
                                        value={valueone}
                                        onChange={handleChange}
                                        valueLabelDisplay="auto"
                                        getAriaValueText={valuetextone}
                                        // step={null}
                                        // marks={mark}
                                        max={100}
                                    />


                                </div>

                                <div className='mt-4'>
                                    <p className='text_sliver'>Days</p>
                                    <div className='d-flex align-items-center justify-content-between progress_text_div'>
                                        <p></p>
                                        <p>{valuetwo} Day</p>

                                    </div>

                                    <Slider
                                        //   getAriaLabel={() => "Temperature range"}
                                        //   value={valuetwo}
                                        //   onChange={handleChangeTwo}
                                        //   valueLabelDisplay="auto"
                                        //   getAriaValueText={valuetexttwo}
                                        getAriaLabel={() => "Temperature range"}
                                        value={valuetwo}
                                        onChange={handleChangeTwo}
                                        valueLabelDisplay="auto"
                                        getAriaValueText={valuetexttwo}
                                        step={null}
                                        marks={mark}
                                        max={3000}
                                    />
                                </div>

                                <div className='mt-4'>

                                    <div className='flex_drop_div'>

                                        <p>Stake Token :</p>

                                        <Dropdown className='dropdwn_trna' onSelect={(eventKey) => { setSelectStakeToken(JSON.parse(eventKey)); selectStakeTokens(eventKey) }}>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic1">

                                                {/* {selectStakeToken ? selectStakeToken.token : stakeTokens[0].token} */}
                                                <img src={eth} className="img-fluid me-1" />{selectStakeToken ? selectStakeToken.token : "Stake Token"}
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                                {stakeTokens && stakeTokens?.map((value, index) =>
                                                    (<Dropdown.Item href="javascript:void(0)" eventKey={JSON.stringify(value)}><img src={eth} className="img-fluid imgwht" />{value?.token}</Dropdown.Item>)
                                                )}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                        <p className='ms-2'>Reward Token :</p>
                                        <Dropdown className='dropdwn_trna me-3' onSelect={(eventKey) => { setSelectRewardToken(JSON.parse(eventKey)) }}>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                <img src={eth} className="img-fluid me-1" />
                                                {selectRewardToken ? selectRewardToken.token : rewardTokenDetails[0].token}
                                            </Dropdown.Toggle>

                                            {/* <Dropdown.Menu>
                                                {rewardTokenDetails && rewardTokenDetails?.map((value, index) =>
                                                    (<Dropdown.Item href="javascript:void(0)" eventKey={JSON.stringify(value)}><img src={eth} className="img-fluid imgwht" />{value?.token}</Dropdown.Item>)
                                                )}
                                            </Dropdown.Menu> */}
                                            <Dropdown.Menu>
                                                {rewardListToken && rewardListToken?.map((value, index) => {

                                                    // if (selectStakeToken?.token == "PAYU") {
                                                    //     if (value?.token === selectStakeToken?.token) {
                                                    //         return ((<Dropdown.Item href="javascript:void(0)" eventKey={JSON.stringify(value)}><img src={eth} className="img-fluid imgwht" />{value?.token}</Dropdown.Item>))
                                                    //     } else if (value?.token === "USDT") {
                                                    //         return ((<Dropdown.Item href="javascript:void(0)" eventKey={JSON.stringify(value)}><img src={eth} className="img-fluid imgwht" />{value?.token}</Dropdown.Item>))
                                                    //     }
                                                    // }
                                                    // else 
                                                    // if (value?.token == selectStakeToken?.token)
                                                    //      {
                                                        return ((<Dropdown.Item href="javascript:void(0)" eventKey={JSON.stringify(value)}><img src={eth} className="img-fluid imgwht" />{value?.token}</Dropdown.Item>))
                                                    // }

                                                }
                                                )}
                                            </Dropdown.Menu>




                                        </Dropdown>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between progress_text_div'>
                                        <p className='mid_value'>{contentLoading ? <PlaceholdingLoader classnames="stripe small-stripe mb-2" parentclassname="loading" /> : reward ? parseInt(reward) : 0} {selectRewardToken ? selectRewardToken.token : rewardTokenDetails[0].token}</p>
                                    </div>

                                    <Slider
                                        getAriaLabel={() => "Temperature range"}
                                        value={reward ? parseInt(reward) : 0}
                                        onChange={handleChangeThree}
                                        valueLabelDisplay="auto"
                                        getAriaValueText={valuetextthree}
                                    />
                                </div>
                                <button className="nav-link btn_yellow  calc_btn mt-4" onClick={() => { calculate() }}><span><img src={calculator} /></span>Calculate</button>
                            </div>
                        </div>

                    </div>
                    <div className='col-12 col-md-6 ps-lg-5'>
                        <h3 className='text_blue banner_txt_yel'>What is cryptocurrency staking?</h3>
                        <p className='banner_txt_desc'>Crypto staking is the practice of locking your digital tokens to a blockchain network in order to earn rewards—usually a percentage of the tokens staked. Staking cryptocurrency is also how token holders earn the right to participate in proof of stake blockchains.
Here’s a simple example: Suppose a blockchain network offers a 30% reward for a staking period of, say, a month. You decide to lock up and stake 100 tokens in the network. After a month, you’re able to access your staked tokens and you receive 30 additional tokens as your reward.</p>
                        <div className='d-flex align-items-center mt-4'>
                            <Link to="/calculate" className="nav-link green_btn">Learn about our PAYU</Link>

                        </div>
                    </div>
                </div>
            </section>
            <section className='container container_custom home_sec_3 mt-5'>
                <div className='bg_shad'>
                    <img src={blue_shadow} />
                </div>
                <div className='row align-items-center'>

                    <div className='col-12 col-md-6'>
                        <h3 className='text_green banner_txt_yel'>Get Multiple Rewards</h3>
                        <p className='banner_txt_desc'>You may have heard that cryptocurrency has its own unique equivalent to fixed-income assets. Instead of earning interest in the form of dollars, you earn a percentage of a batch of crypto coins you set aside and “stake.” This is what crypto staking is all about.</p>
                        <div className='d-flex align-items-center mt-4'>
                            <Link to="/rewards" className="nav-link btn_pink">Learn about Rewards</Link>

                        </div>
                    </div>
                    <div className='col-12 col-md-6 mt-5 mt-md-0'>
                        <img src={reward_img} className="img-fluid" />

                    </div>
                </div>
            </section>
            <section className='container container_custom home_sec_4 mt-5'>
                <h3 className='text_light_blue banner_txt_yel text-center'>How much can I Earn</h3>
                <div className='row'>
                    <div className='col-12 col-md-10 col-lg-9 mx-auto'>
                        <p className='text_silver_desc text-center'>Cryptocurrency staking can take many forms, but it generally falls into two categories: active and passive.</p>

                    </div>
                </div>

                <div className='row align-items-center'>

                    <div className='col-12 col-md-6 mt-3'>
                        <p className='banner_txt_desc'>Active participants may validate transactions and create new blocks to earn token rewards.
 • Passive crypto stakinginvolves simply locking your tokens to a blockchain network to help keep it secure and operating efficiently.</p>

                    </div>
                    <div className='col-12 col-md-6 mt-3'>
                        <p className='banner_txt_desc'>Passive crypto stakinginvolves simply locking your tokens to a blockchain network to help keep it secure and operating efficiently. Passively staking crypto is not time-consuming, but it generally yields lower token rewards than active participation.</p>

                    </div>
                    <div className='col-12'>
                        <div className='d-flex align-items-center mt-4 justify-content-center'>
                            {/* <Link to="/staking" className="nav-link btn_earn" state={{ from: accountInfo }}>Start Earning Now <span><img src={earn_icon} /></span></Link> */}

                        </div>
                    </div>

                </div>
            </section>
            <section className='container container_custom home_sec_5 mt-5'>
                <div className='row align-items-center row_rev'>


                    <div className='col-12 col-md-6 mt-5 mt-md-0'>
                        <img src={graph_img} className="img-fluid" />

                    </div>
                    <div className='col-12 col-md-6'>
                        <h3 className='text_pink banner_txt_yel'>How many ways can crypto investors stake their tokens?
</h3>
                        <p className='banner_txt_desc'>Cryptocurrency staking can take many forms, but it generally falls into two categories: active and passive.
 • Active crypto staking means locking your tokens to a network for the purpose of actively participating in the network. Active participants may validate transactions and create new blocks to earn token rewards.
 • Passive crypto stakinginvolves simply locking your tokens to a blockchain network to help keep it secure and operating efficiently. Passively staking crypto is not time-consuming, but it generally yields lower token rewards than active participation.</p>
                        <div className='d-flex align-items-center mt-4'>
                            {/* <Link to="/" className="nav-link btn_yellow">Learn about our APY</Link> */}

                        </div>
                    </div>
                </div>
            </section>
            <section className='container container_custom home_sec_6 mt-5 pt-4'>
                <div className='bg_shad'>
                    <img src={pink_shadow} />
                </div>
                <h3 className='text_light_blue banner_txt_yel text-center'>What is cryptocurrency staking?
</h3>
                <div className='row'>
                    <div className='col-12 col-md-10 col-lg-9 mx-auto'>
                        <p className='text_silver_desc text-center'>Crypto staking is the practice of locking your digital tokens to a blockchain network in order to earn rewards—usually a percentage of the tokens staked. Staking cryptocurrency is also how token holders earn the right to participate in proof of stake blockchains.
Here’s a simple example: Suppose a blockchain network offers a 30% reward for a staking period of, say, a month. You decide to lock up and stake 100 tokens in the network. After a month, you’re able to access your staked tokens and you receive 30 additional tokens as your reward.</p>

                    </div>
                </div>

                {/* <div>
                    <div className='row mt-5 carousel_home_theme pb-5'>
                        <div className='col-12 col-md-12 col-lg-11 col-xl-10 mx-auto'>
                            <OwlCarousel className='owl-theme pb-3' loop margin={30} nav {...options}>
                                {
                                    poolLimit && poolLimit.map((val, i) =>
                                    ( i == 2 || i == 3 ?

                                        <>

                                            <div class='item'>
                                           
                                              
                                                <div className={'card card_carusel ' + ((i + 1) % 4 == 1 ? " pink_card" : ((i + 1) % 4 == 2) ? " green_card" : ((i + 1) % 4 == 3) ? " purple_card" : "blue_card")} >
                                                    <img src={star} className="img-fluid star_img_carousel" />
                                                    <div className='card-body pb-4 pt-0 px-0'>
                                                      
                                                        <div className='price_sec_div_rad'>
                                                            <p className='text_price'>$<span>{((parseInt(val?.UmudiPerBlock) / 10 ** 18) * Number((val?.lockedPeriod * 1000) / (1000 * 60 * 60 * 24))).toFixed(2)}</span></p>
                                                            <p className='days_grey_price'>{Math.floor(parseInt(val.lockedPeriod * 1000) / (1000 * 60 * 60 * 24))} days</p>
                                                        </div>
                                                        <ul className='card_ul_car ps-0'>
                                                            <li className='mb-2'>
                                                                <i className='fa fa-check check_yellow me-2' />


                                                                <span>10 aliquet dui quis</span>
                                                            </li>
                                                            <li className='mb-2'>
                                                                <i className='fa fa-check check_yellow me-2' />


                                                                <span>10 aliquet dui quis</span>
                                                            </li>
                                                            <li className='mb-2'>
                                                                <i className='fa fa-check check_yellow me-2' />


                                                                <span>10 aliquet dui quis</span>
                                                            </li>

                                                        </ul>
                                                        <div className='d-flex align-items-center text-center mt-4 justify-content-center'>
                                                          
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </>

                                        : '')


                                    )}
                            </OwlCarousel>
                        </div>
                    </div>

                </div> */}

                <div>
                    <div className='row mt-5 carousel_home_theme pb-5'>
                        <div className='col-12 col-md-12 col-lg-11 col-xl-10 mx-auto'>
                            <OwlCarousel className='owl-theme pb-3' loop margin={30} nav {...options}>
                               

                                            <div class='item'>
                                            <div className='card card_carusel  pink_card' >
                                              
                                                {/* <div className={'card card_carusel ' + ((i + 1) % 4 == 1 ? " pink_card" : ((i + 1) % 4 == 2) ? " green_card" : ((i + 1) % 4 == 3) ? " purple_card" : "blue_card")} > */}
                                                    <img src={star} className="img-fluid star_img_carousel" />
                                                    <div className='card-body pb-4 pt-0 px-0'>
                                                        {/* <p className='card_heading_white'>Standard</p> */}
                                                        <div className='price_sec_div_rad'>
                                                            <p className='text_price'><span>13.50 %</span></p>
                                                            <p className='days_grey_price'>180 days</p>
                                                        </div>
                                                        <ul className='card_ul_car ps<span>{((parseInt(va-0'>
                                                            <li className='mb-2'>
                                                                <i className='fa fa-check check_yellow me-2' />


                                                                <span>10 aliquet dui quis</span>
                                                            </li>
                                                            <li className='mb-2'>
                                                                <i className='fa fa-check check_yellow me-2' />


                                                                <span>10 aliquet dui quis</span>
                                                            </li>
                                                            <li className='mb-2'>
                                                                <i className='fa fa-check check_yellow me-2' />


                                                                <span>10 aliquet dui quis</span>
                                                            </li>

                                                        </ul>
                                                        <div className='d-flex align-items-center text-center mt-4 justify-content-center'>
                                                            {/* <button className="nav-link btn_yellow_outline">Choose</button> */}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class='item'>
                                            <div className='card card_carusel  pink_card' >
                                              
                                                {/* <div className={'card card_carusel ' + ((i + 1) % 4 == 1 ? " pink_card" : ((i + 1) % 4 == 2) ? " green_card" : ((i + 1) % 4 == 3) ? " purple_card" : "blue_card")} > */}
                                                    <img src={star} className="img-fluid star_img_carousel" />
                                                    <div className='card-body pb-4 pt-0 px-0'>
                                                        {/* <p className='card_heading_white'>Standard</p> */}
                                                        <div className='price_sec_div_rad'>
                                                            <p className='text_price'><span>30.00 %</span></p>
                                                            <p className='days_grey_price'>365 days</p>
                                                        </div>
                                                        <ul className='card_ul_car ps<span>{((parseInt(va-0'>
                                                            <li className='mb-2'>
                                                                <i className='fa fa-check check_yellow me-2' />


                                                                <span>10 aliquet dui quis</span>
                                                            </li>
                                                            <li className='mb-2'>
                                                                <i className='fa fa-check check_yellow me-2' />


                                                                <span>10 aliquet dui quis</span>
                                                            </li>
                                                            <li className='mb-2'>
                                                                <i className='fa fa-check check_yellow me-2' />


                                                                <span>10 aliquet dui quis</span>
                                                            </li>

                                                        </ul>
                                                        <div className='d-flex align-items-center text-center mt-4 justify-content-center'>
                                                            {/* <button className="nav-link btn_yellow_outline">Choose</button> */}

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                       


                                  
                            </OwlCarousel>
                        </div>
                    </div>

                </div>
            </section>
            <Footer />
            {wallet && <WalletModal onDismiss={() => setShowWallet(false)} />}
        </div>


    )
}

export default Home
