import React, { useEffect, useState } from 'react';
import { Link, NavLink } from "react-router-dom"
import { NavDropdown, Navbar, Container, Nav, Dropdown } from 'react-bootstrap'
import eth from '../images/eth.png';
import { CHAINS } from '../config/env';
import WalletModal from "./WalletModal"
import { setChainId } from '../hooks/useAccount'
import {getChainId} from '../hooks/useAccount'


import logo from '../images/logo_text.png'
import { UseProvider } from '../hooks/useWeb3';

const Header = (props) => {
    console.log("props_props_propsv",props);
    const [colorChange, setColorchange] = useState(false);
    const [currentChain,setcurrentChain]=useState(0)
    console.log("currentChain_currentChaincurrentChain",currentChain);
    const changeNavbarColor = () => {
        if (window.scrollY >= 80) {
            setColorchange(true);
        }
        else {
            setColorchange(false);
        }
    };
    window.addEventListener('scroll', changeNavbarColor);
    const [wallet, setShowWallet] = useState(false);
    const [accountInfo, setAccountInfo] = useState("");

    const chainId = async ()=>{
        let chaindd = getChainId()
         console.log("chaindd_chainddchaindd", chaindd.at,CHAINS[currentChain]);
      //  let chainid =chaindd.CHAIN_ID
    //   console.log("chainid_chainid_chainidv",chainid);
        setcurrentChain(chaindd)

    }

    useEffect(() => {
         chainId()
        //  const index = localStorage.getItem("CHAIN");
        //  console.log("index___indexindex",index);
        //  setcurrentChain(index)
         
        const walletAddress = sessionStorage.accountInfo ? setAccountInfo(sessionStorage.getItem("accountInfo")) : ""
    }, [accountInfo]);

    // useEffect(async()=>{
    //     if(sessionStorage.accountInfo && localStorage.getItem("wcv2")!=null){
    //         const provider = await UseProvider();
    //         provider.on('disconnect',()=> provider.disconnect())
    //     }
       

    // },[])

    const logOut = async () => {
        setAccountInfo("");
        if(sessionStorage.accountInfo && localStorage.getItem("wcv2")!=null){
            const provider = await UseProvider();
            await provider.disconnect();
        }
        sessionStorage.removeItem("accountInfo");
        window.location.reload()
    }

    return (
        <div id="header" className={colorChange ? 'colorChange' : ''}>
            <div className='container container_custom'>
                <Navbar expand="lg">

                    <Navbar.Brand>
                        <NavLink to="/" className="navbar-brand" >
                            <img src={logo} className="img-fluid logoheader" />
                        </NavLink>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <ul className="navbar-nav">
                        <li className="nav-item ml-auto">
                                <a target="blank"  className="nav-link" href ="https://pancakeswap.finance/swap?outputCurrency=0x9AeB2E6DD8d55E14292ACFCFC4077e33106e4144">Buy Now Payu</a>
                            </li>
                            <li className="nav-item ml-auto">
                                <NavLink to="/calculate" className="nav-link">Calculate</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/staking" className="nav-link" state={{ from: accountInfo }}>Staking</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/lockedstaking" className="nav-link">Plans</NavLink>
                            </li>
                            {/* <li className="nav-item">
                                <a href="/" className="nav-link">Market</a>
                            </li> */}
                            {sessionStorage.getItem("accountInfo") == null || undefined || "" ? 
                        <>
                        </>
                        : <>
                        <li className="nav-item">
                                <NavLink to="/rewards" className="nav-link">My Rewards</NavLink>
                            </li>
                        </>
                        }
                            <li className="nav-item">
                                {
                                    (accountInfo == "" || accountInfo == null) ?
                                        <button className="nav-link btn_yellow wallet_btn" onClick={() => { setShowWallet(true) }}>Connect Wallet</button> :
                                        <button className="nav-link btn_yellow wallet_btn" onClick={() => { logOut() }}>{`${accountInfo.substring(0, 5)}...${accountInfo.substring(38, 42)}`}</button>
                                }
                                {/* <button className="nav-link btn_yellow" onClick={()=>{setShowWallet(true)}}>Connect Wallet</button> */}
                            </li>
                            {/* <li className='nav-item'>
                            <Dropdown className='dropdwn_trna dropdow_heade_tar'>
                          <Dropdown.Toggle variant="success" id="dropdown-basic1">
                            <img src={eth} className="img-fluid me-1" />
                          ETH
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                          
                              <Dropdown.Item href="javascript:void(0)"><img src={eth} className="img-fluid imgwht" />ETH</Dropdown.Item>
                              <Dropdown.Item href="javascript:void(0)"><img src={eth} className="img-fluid imgwht" />BSC</Dropdown.Item>

                              
                           
                          </Dropdown.Menu>
                        </Dropdown> 
                            </li> */}
          {/* <Dropdown className="dropdown_yelo mr-3  ms-2" >
  <Dropdown.Toggle variant="success" id="dropdown-basic" className='btn_yellow wallet_btn'>
  {currentChain && CHAINS[currentChain].NAME}
  </Dropdown.Toggle>

  <Dropdown.Menu className='dd_meu_header'>
            { CHAINS.map((index,i)=>
           
    <Dropdown.Item onClick={()=> setChainId(i)}> <img src={index.IMAGE} /> {index.NAME}</Dropdown.Item>)
            }
  </Dropdown.Menu>
</Dropdown> */}
                        </ul>
                    </Navbar.Collapse>

                </Navbar>

            </div>
            {wallet && <WalletModal onDismiss={() => setShowWallet(false)} />}

        </div>
    )
}

export default Header