import React, { useEffect, useState } from "react";

import { Modal,Button, Form } from "react-bootstrap";
import { WalletConnect,BinanceWallet,MetamaskWallet } from "../hooks/useWallet";
import { sleep } from "../hooks/UseContract";
import { useWeb3 } from "../hooks/useWeb3";
import toast, { Toaster } from 'react-hot-toast';
import metamask_sm from '../images/metamask_sm.png'
import wallet_connect_sm from '../images/wallet_connect_sm.png'
import trust_wallet_sm from '../images/trust_wallet_sm.png'
import wallet_con_1 from '../images/wallet_con_1.png'
import wallet_con_2 from '../images/wallet_con_2.png'
import metamask_big from '../images/metamask_big.png'
import wallet_connect_big from '../images/wallet_connect_big.png'
import trust_wallet_big from '../images/trust_wallet_big.png'
import binance from '../images/binance.png'




const WalletModal = (props) =>{

    const [wallet , setShowWallet] = useState(true)  
    const [walletcount,setWalletcount] = useState(0);
    const [accountInfo, setAccountInfo] = useState("");
    const [walletModal, set_walletModal] = useState(true)
    const [walletConnect, set_walletConnect] = useState("")
// MetaMask Wallet Connect
    const MetaMask = async () => {
        await MetamaskWallet()
        await sleep(1000)
        await useWeb3();
        console.log(sessionStorage.getItem("accountInfo"),"ACCOUNT INFO........");
        setAccountInfo(sessionStorage.getItem("accountInfo"))
        console.log(accountInfo,"Wallet  Address........")
        if (sessionStorage.accountInfo) {
            setShowWallet(false)
            toast.success("Wallet Connected successfully", {
                duration: 2000,
                style: {
                    minWidth: '300px',
                    minHeight: '55px'
                }
            })
            window.location.reload()
            // await sleep(1000).then(() => {

            //     window.location.reload()

            // })
        }
    }

// Binance Wallet Connect
    const Binance = async () => {
        await BinanceWallet()
        await sleep(1000)
        await useWeb3();
        setAccountInfo(sessionStorage.getItem("accountInfo"))
        if (sessionStorage.accountInfo) {
            console.log(accountInfo,"Wallet  Address........")
            toast.success("Wallet Connected successfully", {
                duration: 2000,
                style: {
                    minWidth: '300px',
                    minHeight: '55px'
                }
            })
            await sleep(1000)
            // .then(() => {

                window.location.reload()

            // })
        }

    }

// Wallet Connect
    const enableWallet = async () => {
        const provider = await WalletConnect()
        set_walletConnect(provider)

        setAccountInfo(sessionStorage.getItem("accountInfo"))
        //   set_walletModal(false)
        if (sessionStorage.getItem("accountInfo")) {
            set_walletModal(false)
            console.log(accountInfo,"Wallet  Address........")
            toast.success("Wallet Connected successfully", {
                duration: 2000,
                style: {
                    minWidth: '300px',
                    minHeight: '55px'
                }
            })

            //   set_walletModal(false)
            window.location.reload()
        }

    }
    return(
        <div className="signinmodal editprofile">
                
            <Modal show={wallet} size="lg" className="br-15 thememodal walletmodal" centered>
             
                <Modal.Body>
                <button onClick={() => props.onDismiss()} className="close-btn">X</button>
                    <div className="row">
                        <div className="col-12 col-md-5 border_right_md_wallet">
                        <div className="pt-3">
                            <p className="modal_title_white px-2">Connect to a Wallet</p>


                            
                            <p className="modal_subtitle_grey mb-4 px-2">Popular</p>

                            <div className="mb-3">
                                <div className="d-flex align-items-center wallet_connect_div wallet_connect_div_btn" onClick={()=>{setWalletcount(1);MetaMask()}}>
                                   <div className="img_sm_wallet_div">
                                   <img src={metamask_sm} className="img-fluid" />

                                   </div>
                                    <span>MetaMask</span>
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className="d-flex align-items-center wallet_connect_div wallet_connect_div_btn" onClick={()=>{setWalletcount(2);enableWallet()}}>
                                <div className="img_sm_wallet_div">
                                    <img src={wallet_connect_sm} className="img-fluid" />
                                    </div>
                                    <span>WalletConnect</span>
                                </div>
                            </div>
                            <div className="mb-3">
                                <div className="d-flex align-items-center wallet_connect_div wallet_connect_div_btn" onClick={()=>{setWalletcount(3);MetaMask()}}>
                                <div className="img_sm_wallet_div">
                                    <img src={trust_wallet_sm} className="img-fluid" />
                                    </div>
                                    <span>DApps</span>
                                </div>
                            </div>

                            <div className="mb-3">
                                <div className="d-flex align-items-center wallet_connect_div wallet_connect_div_btn" onClick={()=>{setWalletcount(4);Binance()}}>
                                <div className="img_sm_wallet_div">
                                    <img src={binance} className="img-fluid" />
                                    </div>
                                    <span>Binance Wallet</span>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-7 mt-3 pb-3">
                            <div className={walletcount == 0?"default_div":"default_div d-none"}>

                       
                        <p className="wallet_text_center_white">What is a Wallet ?</p>
                        <div className="walletdesc pt-md-4 px-3">
                        <div className="mb-2">
                                <div className="d-flex align-items-start wallet_connect_div">
                                    <img src={wallet_con_1} className="img-fluid" />
                                    <div className="ms-2">
                                        <p className="wallet_text_heading mb-1">A Home for your Digital Assets</p>
                                        <p className="wallet_subtext_heading">Wallet are used to send, receive, store and display digital assets.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="mb-3">
                                <div className="d-flex align-items-start wallet_connect_div">
                                    <img src={wallet_con_2} className="img-fluid" />
                                    <div className="ms-2">
                                        <p className="wallet_text_heading mb-1">A new way to log in</p>
                                        <p className="wallet_subtext_heading">Instead of creating new account and passwords on every website, just connect your wallet.</p>
                                    </div>
                                </div>
                            </div>
                          
                            <div className="text-center">
                            <button className="btn_yellow">Get Wallet</button>
                            <div className="mt-2">
                    <a href="#" className="headright_a">Learn More</a>
                 </div> 
                 <div className="mt-3 a_with_span">
                    <span className="text_desc_a_span">By Connecting your wallet and using PAYU Coin you agree to our</span>
                    <a href="#" className="headright_a">Terms and Conditions.</a>
                 </div> 
                            </div>
                            </div>
                            </div>

                            <div className={walletcount == 1?"metamask_div wallet_divs":"metamask_div wallet_divs d-none"}>

                       
<p className="wallet_text_center_white">MetaMask</p>
<div className="my-5">
    <div className="text-center">
    <img src={metamask_big} className="img-fluid big_image_wallet" />
           

           <p className="modal_subtitle_grey">
           <i className="fa fa-spinner spin_animation pe-1" aria-hidden="true"></i>
           Connecting</p>
    </div>
       
           
           
    </div>

    <div className="">
        <div className="d-flex align-items-center justify-content-between">
        <p className="modal_subtitle_grey mb-0">Confirm the MetaMask connection</p>
            <div className="ms-2">
            <button className="btn_yellow wallet_btn">Retry</button>
            </div>
        </div>
    </div>

    
    </div>



    <div className={walletcount == 2?"metamask_div wallet_divs":"metamask_div wallet_divs d-none"}>

                       
<p className="wallet_text_center_white">WalletConnect</p>
<div className="my-5">
    <div className="text-center">
    <img src={wallet_connect_big} className="img-fluid big_image_wallet" />
           

           <p className="modal_subtitle_grey">
           <i className="fa fa-spinner spin_animation pe-1" aria-hidden="true"></i>
           Connecting</p>
    </div>
       
           
           
    </div>

    <div className="">
        <div className="d-flex align-items-center justify-content-between">
        <p className="modal_subtitle_grey mb-0">Confirm the WalletConnect connection</p>
            <div className="ms-2">
            <button className="btn_yellow wallet_btn">Retry</button>
            </div>
        </div>
    </div>

    
    </div>

    <div className={walletcount == 3?"metamask_div wallet_divs":"metamask_div wallet_divs d-none"}>

                       
<p className="wallet_text_center_white">Trust Wallet</p>
<div className="my-5">
    <div className="text-center">
    <img src={trust_wallet_big} className="img-fluid big_image_wallet" />
           

           <p className="modal_subtitle_grey">
           <i className="fa fa-spinner spin_animation pe-1" aria-hidden="true"></i>
           Connecting</p>
    </div>
       
           
           
    </div>

    <div className="">
        <div className="d-flex align-items-center justify-content-between">
        <p className="modal_subtitle_grey mb-0">Confirm the Trust Wallet connection</p>
            <div className="ms-2">
            <button className="btn_yellow wallet_btn">Retry</button>
            </div>
        </div>
    </div>

    
    </div>

                            </div>
                    </div>
                
                    {/* <div><Toaster /></div> */}
                 
                </Modal.Body>
            </Modal>
        </div>
    )
}


export default WalletModal