import React, { useState } from 'react';
import {Link} from "react-router-dom"
import {InputGroup,Form,OverlayTrigger,Button,Popover} from 'react-bootstrap'
import WalletModal from "./WalletModal"

import logo from '../images/logo.png'

const Footer = (props) =>{

  
    return(
        <div id="footer">
            <div className='container container_custom'>
                <div className='py-3'>
                <div className='d-flex align-items-center justify-content-between'>
                <span className='text_footer'>CopyRight @ 2023. All Rights Reserved</span>
                <div className='d-inline-flex align-items-center social_icon_div text-right'>
                    <a href="/" target="_blank">
                    <i className="fa fa-facebook-square" aria-hidden="true"></i>
                    </a>
                    <a href="/" target="_blank">
                    <i className="fa fa-twitter-square" aria-hidden="true"></i>

                    </a>
                    <a href="/" target="_blank">
                    <i className="fa fa-instagram" aria-hidden="true"></i>

                    </a>
            </div>
            </div>
            
                </div>
           
            </div>

          
        </div>
    )
}

export default Footer